<template>
    <section>
        <div class="card-header">
            <h3 class="tour-title-header">Danh sách đơn hàng đã đặt</h3>
            <filter-order-zone :status="status" :id="'filter-order-zone'" @getResults="getOrderResults" ></filter-order-zone>
        </div>
        <div class="card-body table-responsive p-0">
            <order-list @getOrderResults="getOrderResults" :selected_startdate="selected_startdate" :orders="order_list"></order-list>
        </div>
        <div class="card-footer">
            <pagination v-if="order_list.data" :data="order_list" @pagination-change-page="getOrderResults"></pagination>
        </div>
    </section>
</template>
<script>
    export default {
        data() {
            return {
                status : [],
                tour_type : false ,
                order_list : {} ,
                selected_startdate : false,
                search_form : new Form({
                    startdate : false,
                    status : [],
                    name :'',
                    code : ''
                }),
            }
        }, mounted() {
            this.$Progress.start();
            // console.log('Dashboard Component mounted.')
            if(!window.status_list){
                this.$helpers.getStatusList().then((response)=> {
                    this.status = response.data.map((x,k)=> {
                        return {
                            name : x,
                            value : k
                        }
                    });
                    window.status_list = this.status ;
                    this.$Progress.finish();
                });
            } else {
                this.status = window.status_list;
                this.$Progress.finish();
            }
        }, methods: {
            getOrderResults (page = 1 ,filter ) {
                this.$Progress.start();
                if(!page){
                    page = 1;
                }
                if(filter && filter.from_child){
                    this.search_form = new Form({ ... filter});
                }
                if(!filter){
                    filter = {... this.search_form};
                }
                filter.page = page ;
                filter.is_owner = 1;
                this.$helpers.getOrders(filter).then((response) => {
                    this.$Progress.finish();
                    this.order_list = response.data;
                }).catch(errors => {
                    this.$Progress.fail();
                });
            }
        }, created() {
            this.$Progress.start();

            this.$Progress.finish();
        }
    }
</script>

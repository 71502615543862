<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/vi';
    export default {
        components: { DatePicker },
        data() {
            return {
                day : new Date()
            };
        },
        methods : {
            // day() {
            //     // this.$emit('input', value)
            // }
        },
        watch : {
            day (to,from) {
                if(to != from){
                    this.$emit('changeDay' ,to);
                }
            }
        },
        created() {
            // this.$emit('changeDay' ,this.day);
        }
    };
</script>
<template>
    <div>
        <date-picker v-model="day" :format="'DD/MM/YYYY'" :defaultValue="new Date()" value-type="format" range></date-picker>
    </div>
</template>

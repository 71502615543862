<template>
    <div class="sidebar" v-if="user">
        <router-link to="/profile">
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image">
                    <img :src=avatar_base_url  class="img-circle elevation-2" alt="User Image" id="avatar_user">
                </div>
                <div class="info text-center justify-content-center w-100">
                    {{ user.fullname}}
                    <span class="d-block text-muted">
                        {{ user.role }}
                      </span>
                </div>
            </div>
        </router-link>
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <router-link to="/dashboard" class="nav-link">
                        <i class="nav-icon fas fa-tachometer-alt blue"></i>
                        <p>Dashboard</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/tour-nuoc-ngoai" class="nav-link">
                        <i class="nav-icon fas fa-plane red"></i>
                        <p>Tour nước ngoài</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/tour-trong-nuoc" class="nav-link">
                        <i class="fas fa-plane nav-icon green"></i>
                        <p>Tour trong nước</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/help" class="nav-link">
                        <i class="fas fa-book-open nav-icon red"></i>
                        <p>Hướng dẫn</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/change_password" class="nav-link">
                        <i class="fa fa-key nav-icon blue"></i>
                        <p>Đổi mật khẩu</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <a href="/logout" class="nav-link"> <i class="nav-icon fas fa-power-off red"></i>
                        <p>Log out</p>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                user : false,
                avatar_base_url : '',
                current_route_path : ''
            }
        },
        mounted() {
            this.current_route_path = this.$route.path;
            this.user = window.user;
            this.avatar_base_url = window.user ? (window.avatar_base_url + window.user.avatar)  : '/images/profile.png';
        },
        created() {

        },
        watch:{
            $route (to, from){
                // console.log(from, to);
                this.current_route_path = to.path;
                // console.log(this.current_route_path);
                if(from.path === '/login'){
                    this.user = window.user;
                    this.avatar_base_url = window.user ? (window.avatar_base_url + window.user.avatar)  : '/images/profile.png';
                }
            }
        }
    }
</script>

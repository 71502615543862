import moment from 'moment';
const getDestinations = async (tour_type) => {
    if(window['destinations_'+ tour_type]){
        return window['destinations_'+ tour_type];
    }
    return await axios.post('/destinationpoint', {tour_type : tour_type}).then(response => window['destinations_'+ tour_type] = response.data);
}
const getSubjects = async (tour_type) => {
    if(window['subjects_'+ tour_type]){
        return window['subjects_'+ tour_type];
    }
    return await axios.post('/subject', {tour_type : tour_type}).then(response => window['subjects_'+ tour_type] = response.data);
}
const getTourCode =  (tour) => {
    var id_part = tour.id + '';
    let total_char = id_part.length ;
    for (var i = total_char; i < 5; i++) {
        id_part = '0' + id_part;
    }
    return 'HD' + id_part + moment(new Date(tour.created_at)).format('YYYY');
}
const getTours = (filter) => {
    return axios.post('/tour/agency', filter);
}
const getOrders = (filter) => {
    return axios.post('/tour/agency/orders', filter);
}
const getBookPrice = (data) => {
    return axios.post('/tour/agency/bookprice', data);
}
const getStatusList = () => {
    return axios.get('/tour/agency/status');
}
const changeBook = (order_id) => {
    return axios.get('/tour/agency/changeBook?order_id='+order_id);
}
const deleteBook = (order_id) => {
    return axios.get('/tour/agency/deleteBook?order_id='+order_id);
}
const changePassword = (data) => {
    return axios.post('/user/ChangePassword', data);
}
const getProfile = () => {
    return axios.get('/tour/agency/profile');
}
export default { getDestinations , getSubjects , getTours , getTourCode , getOrders , getBookPrice , getStatusList , changeBook , deleteBook , changePassword , getProfile};

<template>
  <div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6 col-lg-3">
                <label>Mã đơn</label>
                <input type="text" class="form-control" name="code" v-model="searchForm.code" placeholder="Mã đơn">
                <has-error :form="searchForm" field="code"></has-error>
            </div>
            <div class="col-6 col-lg-3">
                <label>Tên tour</label>
                <input type="text" class="form-control" name="name" v-model="searchForm.name" placeholder="Tên tour">
                <has-error :form="searchForm" field="name"></has-error>
            </div>
            <div class="col-6 col-lg-3">
                <div class="form-group">
                    <label>Ngày khởi hành</label>
                    <datepicker @changeDay="changeDayDB" ></datepicker>
                    <has-error :form="searchForm" field="startdate"></has-error>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="form-group">
                    <label>Trạng thái đơn</label>
                    <multiselect
                        :custom-label="onlyName"
                        v-model="searchForm.status"
                        placeholder="Chọn"
                        :options="status"
                        track-by="name"
                        deselectGroupLabel="Nhấn enter để bỏ chọn"
                        deselectLabel="Nhấn enter để bỏ chọn"
                        selectGroupLabel="Nhấn enter để chọn"
                        selectLabel="Nhấn enter để chọn"
                        :multiple="true">
                    </multiselect>
                    <has-error :form="searchForm" field="status"></has-error>
                </div>
            </div>
            <div class="col-12 col-lg-9 d-flex">
                <div class="">
                    <span class="p-2 pl-4 border bg-danger">&nbsp;</span>
                    <label class="ml-2 mr-2">
                        Đơn huỷ
                    </label>
                </div>
                <div class="">
                    <span class="p-2 pl-4 border pl-4">&nbsp;</span>
                    <label class="ml-2 mr-2">
                        Đơn giữ chỗ 24h
                    </label>
                </div>
                <div class="">
                    <span class="p-2 pl-4 border bg-primary">&nbsp;</span>
                    <label class="ml-2 mr-2">
                        Đơn đặt chỗ
                    </label>
                </div>
                <div class="">
                    <span class="p-2 pl-4 border bg-success">&nbsp;</span>
                    <label class="ml-2 mr-2">
                        Đơn xác nhận hay hoàn tất
                    </label>
                </div>
            </div>
            <div class="col-12 col-lg-3 text-right">
                <button type="button" class="btn btn-primary" @click="getFilterResults()"><i class="fa fa-search"></i> Tìm</button>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
    export default {
        props : {
            status : Array,
        },
        data () {
            return {
                searchForm :  new Form({
                    startdate : false,
                    status : [],
                    name :'',
                    code : ''
                }),
            }
        },
        methods: {
            changeDayDB(newDay) {
                this.searchForm.startdate =  newDay ;
            },
            selectTourTypeChange(val){
                // console.log(val);
            },
            selectTourTypeSelect({id, text}){
                // console.log({id, text})
            },
            onlyName ({ name, value }) {
                return name ;
            },
            getFilterResults(page = 1){
                this.$emit('getResults', page , { search_date : this.searchForm.startdate , search_status : this.searchForm.status , search_name : this.searchForm.name , search_code : this.searchForm.code , from_child :   true} );
            }
        },
        mounted () {
            this.getFilterResults();
            // console.log('Filter Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.$Progress.finish();
        }
    }
</script>

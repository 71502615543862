export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/login', component: require('./components/auth/Login.vue').default },
    { path: '/tour-trong-nuoc', component: require('./components/Tour').default  , props: { tour_type :  0}},
    { path: '/tour-nuoc-ngoai', component: require('./components/Tour').default , props: { tour_type : 1}},
    { path: '/booking/tour/:id' , name: 'booking' , props : true , component: require('./components/Booking').default},
    { path: '/booking/order/:order_id' , name: 'booking-order' , props : true , component: require('./components/Booking').default},
    { path: '/help', component: require('./components/Help').default },
    { path: '/change_password', component: require('./components/ChangePassword.vue').default },
    { path: '*', component: require('./components/Dashboard.vue').default }
];

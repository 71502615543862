<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th>STT</th>
            <th>Mã đơn</th>
            <th>Tên tour</th>
            <th>Ngày khởi hành</th>
            <th>Tiêu chuẩn</th>
            <th>Người quản lý</th>
            <th>Ngày tạo</th>
            <th>Trạng thái đơn</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(order,index) in orders.data" :key="'order-'+order.id" :class="order.status | getOrderBgRow">
            <td>{{ (orders.current_page-1)*(orders.per_page) + (index+1)}}</td>
            <td>{{order.code}}</td>
            <td>{{selected_startdate ? selected_startdate.tour_name : order.startdate.tour_name }}</td>
            <td>{{(selected_startdate ? selected_startdate.startdate : order.startdate.startdate) | formatDate }}</td>
            <td>{{selected_startdate ? selected_startdate.tour_starhotel : order.startdate.tour_starhotel }} sao</td>
            <td>{{order.tour_staff_name }} - {{ order.tour_staff_phone}}</td>
            <td>{{order.created_at | formatDateTime}}</td>
            <td>{{order.status_name }}</td>
            <td class="action-button">
                <span class="">
                <button  type="button" class="btn btn-success" @click="addCustomerInfo(order.id)" title="Xem thông tin đơn hàng">
                    <i class="fa fa-eye text-white"></i>
                </button>
                </span>
                <span class="" v-if="order.can_book">
                    <button  type="button" class="btn btn-primary" @click="changeBook(order.id)" title="Chuyển trạng thái đơn sang đặt">
                    <i class="fa fa-check-square text-white"></i>
                    </button>
                </span>
                <span v-if="order.can_update" class="">
                    <button  type="button" class="btn btn-primary" @click="addCustomerInfo(order.id)" title="Cập nhật thông tin khách hàng">
                        <i class="fa fa-edit text-white"></i>
                    </button>
                </span>
                <span v-if="order.can_delete" class="">
                    <button  type="button" class="btn btn-danger" @click="cancelOrder(order.id)" title="Huỷ đơn">
                        <i class="fa fa-trash text-white"></i>
                    </button>
                </span>
            </td>
        </tr>
        </tbody>
    </table>
</template>
<style>
    .action-button {
        min-width: 200px;
        padding-right: 1rem!important;
    }
</style>
<script>
    export default {
        props :{
            orders : Object ,
            selected_startdate: {
                type: [Object, Boolean ]
            }
        },
        data () {
            return {

            }
        },
        methods: {
            cancelOrder (order_id) {
                Swal.fire({
                    title: 'Bạn có chắc chắn muốn huỷ vé này ?',
                    text: "Vé đặt chỗ này sẽ được huỷ ngay lập tức !",
                    showCancelButton: true,
                    confirmButtonColor: '#ff0000',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Đồng ý !'
                }).then((result) => {
                    // console.log(result);
                    // Send request to the server
                    if (result.value) {
                        this.$helpers.deleteBook(order_id).then(()=>{
                            Swal.fire(
                                'Thành công!',
                                'Đã huỷ đơn thành công',
                                'success'
                            );
                            $('#showBook').modal('hide');
                            // Fire.$emit('AfterCreate');
                            if(this.$router.history.current.path.includes('dashboard')){
                                this.$emit('getOrderResults',1);
                            } else {
                                this.$router.push('/dashboard');
                            }
                        }).catch((error)=> {
                            let message = 'Something went wrong. Please try again.' ;
                            if (typeof error.response.data === 'object') {
                                message = error.response.data.message;
                            }
                            Swal.fire("Thất bại !", message, "warning");
                        });
                    }
                })
            },
            changeBook(order_id) {
                Swal.fire({
                    title: 'Chuyển đổi đơn từ giữ chỗ 24h sang đặt chỗ ?',
                    text: "Việc chuyển đổi sẽ tạo thuận tiện cho việc lên đơn hàng và sắp xếp chỗ hành khách nhanh hơn !",
                    showCancelButton: true,
                    confirmButtonColor: '#00dd4e',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Đồng ý !'
                }).then((result) => {
                    // console.log(result);
                    // Send request to the server
                    if (result.value) {
                        this.$helpers.changeBook(order_id).then(()=>{
                            Swal.fire(
                                'Thành công!',
                                'Chuyển đổi đơn đặt chỗ thành công ! Đơn sẽ không bị huỷ sau 24h',
                                'success'
                            );
                            $('#showBook').modal('hide');
                            // Fire.$emit('AfterCreate');
                            if(this.$router.history.current.path.includes('dashboard')){
                                this.$emit('getOrderResults',1);
                            } else {
                                this.$router.push('/dashboard');
                            }
                        }).catch((error)=> {
                            let message = 'Something went wrong. Please try again.' ;
                            if (typeof error.response.data === 'object') {
                                message = error.response.data.message;
                            }
                            Swal.fire("Thất bại !", message, "warning");
                        });
                    }
                });
            },
            addCustomerInfo(order_id) {
                if(this.selected_startdate){
                    $('#showBook').modal('hide');
                }
                this.$router.push({ path : '/booking/order/'+order_id});
            }
        },
        mounted () {
            // console.log('OrderList Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.$Progress.finish();
        }
    }
</script>

<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Đăng nhập</div>

                    <div class="card-body">
                        <form method="POST" action="">
                            <div class="form-group row">
                                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>
                                <div class="col-md-6">
                                    <input id="email" type="email" v-model="user.UserName" class="form-control " name="email" value="" required autocomplete="email" autofocus>
                                    <span v-show="error.UserName" class="invalid-feedback" role="alert">
                                        <strong>{{ error.UserName }}</strong>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                <div class="col-md-6">
                                    <input id="password" v-model="user.Password" type="password" :class="{ 'is-invalid' : error.Password }" class="form-control" name="password" required autocomplete="current-password">
                                    <span v-if="error.Password" class="invalid-feedback" role="alert">
                                        <strong>{{ error.Password }}</strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="button" class="btn btn-primary" @click="login">Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        /*
         * The component's data.
         */
        data() {
            return {
                user : {
                    UserName : '',
                    Password : ''
                },
                error : {
                    UserName : '',
                    Password : ''
                }
            };
        },

        /**
         * Prepare the component (Vue 1.x).
         */
        ready() {
            this.prepareComponent();
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.prepareComponent();
        },

        methods: {
            /**
             * Prepare the component.
             */
            prepareComponent() {
                if(window.user) {
                    this.$router.push('/dashboard');
                }
            },

            /**
             * Get all of the OAuth clients for the user.
             */
            getClients() {
                axios.get('/oauth/clients')
                        .then(response => {
                            this.clients = response.data;
                        });
            },

            /**
             * Show the form for creating new clients.
             */
            showCreateClientForm() {
                $('#modal-create-client').modal('show');
            },

            /**
             * Create a new OAuth client for the user.
             */
            store() {
                this.persistClient(
                    'post', '/oauth/clients',
                    this.createForm, '#modal-create-client'
                );
            },

            /**
             * Edit the given client.
             */
            edit(client) {
                this.editForm.id = client.id;
                this.editForm.name = client.name;
                this.editForm.redirect = client.redirect;

                $('#modal-edit-client').modal('show');
            },

            /**
             * Update the client being edited.
             */
            update() {
                this.persistClient(
                    'put', '/oauth/clients/' + this.editForm.id,
                    this.editForm, '#modal-edit-client'
                );
            },

            /**
             * Persist the client to storage using the given form.
             */
            persistClient(method, uri, form, modal) {
                form.errors = [];

                axios[method](uri, form)
                    .then(response => {
                        this.getClients();

                        form.name = '';
                        form.redirect = '';
                        form.errors = [];

                        $(modal).modal('hide');
                    })
                    .catch(error => {
                        if (typeof error.response.data === 'object') {
                            form.errors = _.flatten(_.toArray(error.response.data.errors));
                        } else {
                            form.errors = ['Something went wrong. Please try again.'];
                        }
                    });
            },

            /**
             * Destroy the given client.
             */
            destroy(client) {
                axios.delete('/oauth/clients/' + client.id)
                        .then(response => {
                            this.getClients();
                        });
            },
            login(){
                if(this.user.UserName && this.user.Password){
                    axios.post('CustomerLogin', this.user)
                        .then(response => {
                            window.user = user =  response.data.data[0];
                            axios.post('registerToken', user , {
                                baseURL : '/',
                                headers : {
                                    'X-CSRF-TOKEN' : document.head.querySelector('meta[name="csrf-token"]').content
                                }
                            }).then(response => {
                                window.axios.defaults.headers.common['Authorization'] =  'Bearer ' + user.token ;
                                this.$router.push('/dashboard');
                            });
                        })
                        .catch(error => {
                            if(error.response && error.response.data && error.response.data.status  == 'error') {
                                this.error.Password = error.response.data.data[0]['message'];
                            }
                        });
                } else {
                    this.error.Password = 'Tài khoản và mật khẩu không được để trống';
                }
            },
        }
    }
</script>

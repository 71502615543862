<template>
    <section>
         <div class="row">
                <div class="col-12">
                    <form @submit.prevent="bookingInfo()">
                        <div class="card border-main">
                            <div class="card-header">
                                <h3 class="tour-title-header color-main">Booking {{ selected_startdate.tour_name}}</h3>
                                <h3 class="tour-title-header color-sub" v-if="order_id && order">Order : {{ order.code }}</h3>
                                <h3 class="tour-title-header color-sub" v-if="order_id && order">Tình trạng : {{ order.status_name }} <span v-if="order.approve_date">{{ order.approve_date | formatDate }}</span>
                                    <button v-if="order.can_book" class="btn btn-success" type="button" @click="changeBook(order_id)"><i class="fa fa-angle-up"></i> Đặt đơn</button>
                                </h3>
                                <h5 class="tour-title-header">Khởi hành {{ selected_startdate.startdate | formatDate }} </h5>
                                <h5 class="tour-title-header">Quản lý : {{ selected_startdate.tour_staff_name }} - {{ selected_startdate.tour_staff_phone}}</h5>
                                <div class="row" v-if="selected_startdate.traffic_start_info && selected_startdate.traffic_start">
                                    <div class="col-12 col-lg-4"><i class="fa fa-calendar-days text-primary pr-2"></i><span class="text-primary">{{ selected_startdate.traffic_start }}</span></div>
                                    <div class="col-12 col-lg-8 text-primary">
                                        <i class="fa fa-train text-primary " v-if="selected_startdate.traffic == 9"></i>
                                        <i class="fa fa-plane text-primary" v-else-if="selected_startdate.traffic == 10"></i>
                                        <i class="fa fa-bus text-primary" v-else></i>
                                        <span class="text-primary pl-2">{{ selected_startdate.traffic_start_info }}</span>
                                    </div>
                                </div>
                                <div class="row" v-if="selected_startdate.traffic_end_info && selected_startdate.traffic_end">
                                    <div class="col-12 col-lg-4"><i class="fa fa-calendar-days text-success pr-2"></i><span class="text-success">{{ selected_startdate.traffic_end }}</span></div>
                                    <div class="col-12 col-lg-8 text-success">
                                        <i class="fa fa-train text-success" v-if="selected_startdate.traffic == 9"></i>
                                        <i class="fa fa-plane text-success" v-else-if="selected_startdate.traffic == 10"></i>
                                        <i class="fa fa-bus text-success" v-else></i>
                                        <span class="text-success pl-2">{{ selected_startdate.traffic_end_info }}</span>
                                    </div>
                                </div>
                                <div class="font-weight-bold text-danger">Ghi chú : {{ selected_startdate.notice_agency}}</div>
                                <div class="text-center text-blue" v-if="selected_startdate.tour_guide_info">
                                    <i class="fa fa-flag"></i><span class="font-weight-bold pl-2">TL : {{ selected_startdate.tour_guide_info }}</span>
                                </div>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead class="text-center">
                                    <tr>
                                        <th>Thông tin giá và phụ thu</th>
                                        <th>Người lớn</th>
                                        <th>Trẻ em</th>
                                        <th>Em bé</th>
                                    </tr>
                                    </thead>
                                    <tbody class="text-right">
                                    <tr>
                                        <td class="text-left">Giá tour cơ bản</td>
                                        <td>{{ selected_startdate.adult_price | toCurrency  }}</td>
                                        <td>{{ selected_startdate.child_price | toCurrency }}</td>
                                        <td>{{ selected_startdate.baby_price | toCurrency }}</td>
                                    </tr>
                                    <template v-if="selected_startdate.addings">
                                        <tr v-for="(adding,index) in selected_startdate.addings" :key="'adding'+index">
                                            <td class="text-left" :class="adding.required ==='text-danger'?'':''">{{ adding.name }} {{ adding.required ==='true'?'(khoản bắt buộc)':'' }}</td>
                                            <td colspan="3">{{ adding.price | toCurrency }}</td>
                                        </tr>
                                    </template>
                                    <template v-if="voucher">
                                        <tr>
                                            <td class="font-weight-bold">Giảm voucher : </td>
                                            <td class="text-blue">- {{ voucher.value | toCurrency }}</td>
                                            <td>Code : {{ voucher.code }}</td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <td class="font-weight-bold">Tổng cộng : </td>
                                        <td class="font-weight-bold text-danger">{{ result_price | toCurrency}}</td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-bold">Tổng huê hồng : </td>
                                        <td class="font-weight-bold text-blue">{{ result_price_commission | toCurrency}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                    <h5 class="pt-3">Thông tin người người đại diện</h5>
                                    <input type="hidden" name="order_id" v-model="form.id">
                                    <div class="row">
                                        <div class="col-12 col-lg-4">
                                            <input type="text" v-model="form.fullname" class="form-control" name="fullname" placeholder="Hộ tên người đại diện(*)" :class="{ 'is-invalid': form.errors.has('fullname') }">
                                            <has-error :form="form" field="fullname"></has-error>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <div class="input-group">
                                                <input type="text" name="phone" v-model="form.phone" class="form-control " placeholder="Số điện thoại người mua hay đại diện" :class="{ 'is-invalid': form.errors.has('phone') }">
                                                <div class="input-group-append">
                                                    <button class="btn btn-success" type="button" @click="getPhone()">
                                                        <i class="fa fa-phone"></i>
                                                    </button>
                                                </div>
                                                <has-error :form="form" field="phone"></has-error>
                                            </div>

                                            <small id="phoneHelp" class="form-text text-muted text-right">Lấy thông tin của tài khoản hiện tại</small>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <input type="text" class="form-control" name="tour_phone" v-model="form.tour_phone" placeholder="Số điện thoại người mua tour" :class="{ 'is-invalid': form.errors.has('tour_phone') }">
                                            <has-error :form="form" field="tour_phone"></has-error>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-4">
                                            <input type="text" class="form-control" name="address" v-model="form.address" placeholder="Địa chỉ(*)" :class="{ 'is-invalid': form.errors.has('address') }">
                                            <has-error :form="form" field="address"></has-error>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <div class="input-group">
                                                <input type="text" name="email" v-model="form.email" class="form-control " placeholder="Nhập email" :class="{ 'is-invalid': form.errors.has('email') }">
                                                <div class="input-group-append">
                                                    <button class="btn btn-success" type="button" @click="getEmail()">
                                                        <i class="fa fa-envelope"></i>
                                                    </button>
                                                </div>
                                                <has-error :form="form" field="email"></has-error>
                                            </div>
                                            <small id="emailHelp" class="form-text text-muted text-right">Lấy thông tin của tài khoản hiện tại</small>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <input type="text" class="form-control" name="voucher" v-model="form.voucher" placeholder="Mã voucher" :class="{ 'is-invalid': form.errors.has('voucher') }">
                                            <has-error :form="form" field="voucher"></has-error>
                                        </div>
                                    </div>
                                    <h5 class="pt-3">Số lượng khách</h5>
                                    <div class="row">
                                        <div class="col-12 col-lg-4">
                                            <div class="form-group row">
                                                <label for="adult" class="col-sm-4 col-form-label">Người lớn(*)</label>
                                                <div class="col-sm-8">
                                                    <input required @change="getPrice()" type="number" v-model="form.adult" name="adult" class="form-control" id="adult" placeholder="" :class="{ 'is-invalid': form.errors.has('adult') }">
                                                    <has-error :form="form" field="adult"></has-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <div class="form-group row">
                                                <label for="child" class="col-sm-4 col-form-label">Trẻ em (2-11t)</label>
                                                <div class="col-sm-8">
                                                    <input required @change="getPrice()" type="number" v-model="form.child" name="child" class="form-control" id="child" placeholder="" :class="{ 'is-invalid': form.errors.has('child') }">
                                                    <has-error :form="form" field="child"></has-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <div class="form-group row">
                                                <label for="baby" class="col-sm-4 col-form-label">Em bé (dưới 2t)</label>
                                                <div class="col-sm-8">
                                                    <input required @change="getPrice()" type="number" v-model="form.baby" name="baby" class="form-control" id="baby" placeholder="" :class="{ 'is-invalid': form.errors.has('baby') }">
                                                    <has-error :form="form" field="baby"></has-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="pt-3">Ghi chú : (Gửi đến nhân viên quản lý)</h5>
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group">
                                                <label>Ghi chú : </label>
                                                <textarea v-model="form.notice" name="notice" class="form-control" rows="3" :class="{ 'is-invalid': form.errors.has('notice') }" placeholder="Nhập ghi chú"></textarea>
                                                <has-error :form="form" field="notice"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <label class="form-check">
                                                <input type="checkbox" v-model="form.is_vat" name="is_vat" class="form-check-input" id="is_vat">
                                                <label class="form-check-label" for="is_vat">Xuất VAT</label>
                                            </label>
                                            <div class="form-group">
                                                <textarea v-model="form.vat_info" name="vat_info" class="form-control " rows="3" :class="{ 'is-invalid': form.errors.has('vat_info') }" placeholder="Nhập thông tin xuất"></textarea>
                                                <has-error :form="form" field="vat_info"></has-error>
                                            </div>
                                        </div>
                                    </div>

                            </div>
                            <!-- /.card-body -->
                            <div class="card-footer text-right" v-if="(order.can_update && order.from_agency )|| !order_id">
                                <button class="btn btn-danger" v-if="order_id && order.can_update" type="button" @click="deleteOrder(order_id)"><i class="fa fa-trash"></i> Xoá đơn</button>
                                <button class="btn btn-warning" type="button" @click="resetForm()"><i class="fa fa-refresh"></i> Nhập lại</button>
                                <button class="btn btn-success" type="submit"><i class="fa fa-save"></i> Lưu thông tin</button>
                            </div>
                        </div>
                    </form>
                    <!-- /.card -->
                </div>
            </div>
    </section>
</template>

<script>
    export default {
        props: {
            id : String,
            order_id : String
        },
        data () {
            return {
                selected_startdate : {},
                form : new Form({
                    id : '',
                    order_id  : '',
                    fullname : '',
                    address : '',
                    phone : '',
                    email : '',
                    tour_phone : '',
                    voucher : '',
                    adult : 1 ,
                    child : 0 ,
                    baby : 0 ,
                    is_vat : 0 ,
                    vat_info : '',
                    notice : ''
                }),
                has_result :  false ,
                result_price :  0,
                result_price_commission :  0,
                in_progress : false ,
                order : {} ,
                voucher : false
            }
        },
        methods: {
            updateInfo() {
                Swal.fire({
                    title: 'Bạn có chắc chắn muốn cập nhật các thông tin này ?',
                    text: "Việc cập nhật lại thông tin sẽ tốn thời gian xác minh đơn hàng !",
                    showCancelButton: true,
                    confirmButtonColor: '#ddc301',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Đồng ý !'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        this.form.post('tour/agency/book').then(()=>{
                            Swal.fire(
                                'Cập nhật thành công!',
                                'Cập nhật thông tin booking thành công !',
                                'success'
                            );
                            this.$router.push('/dashboard');
                        }).catch((error)=> {
                            let message = 'Something went wrong. Please try again.' ;
                            if (typeof error.response.data === 'object') {
                                message = error.response.data.message;
                            }
                            Swal.fire("Thất bại !", message, "warning");
                        });
                    }
                })
            },
            bookingInfo() {
                if(this.order_id){
                    return this.updateInfo();
                }
                Swal.fire({
                    title: 'Bạn có chắc chắn muốn booking giữ vé với các thông tin này ?',
                    text: "Tạo thông tin giữ vé 24h - sau 24h các vé không đc chuyển trạng thái sẽ bị huỷ",
                    showCancelButton: true,
                    confirmButtonColor: '#00dd4e',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Đồng ý !'
                }).then((result) => {
                    // console.log(result);
                    // Send request to the server
                    this.form.id = this.selected_startdate.id ;
                    if (result.value) {
                        this.form.post('tour/agency/book').then(()=>{
                            Swal.fire(
                                'Thành công!',
                                'Đơn giữ chỗ của bạn đã được tạo thành công !',
                                'success'
                            );
                            // Fire.$emit('AfterCreate');
                            this.$router.push('/dashboard');
                        }).catch((error)=> {
                            let message = 'Something went wrong. Please try again.' ;
                            if (typeof error.response.data === 'object') {
                                message = error.response.data.message;
                            }
                            Swal.fire("Thất bại !", message, "warning");
                        });
                    }
                });
            },
            getPhone () {
                this.form.phone = window.user.phone ;
            },
            getEmail() {
                this.form.email = window.user.email ;
            },
            resetForm() {
                this.form.reset();
            },
            getPrice () {
                let data = {
                  id : this.selected_startdate.id ,
                  adult : this.form.adult ,
                  child : this.form.child ,
                  baby : this.form.baby
                };
                if(!this.in_progress){
                    this.in_progress = true;
                    this.$Progress.start();
                    this.$helpers.getBookPrice(data).then((response)=>{
                        this.$Progress.finish();
                        this.form.errors.errors = {};
                        // console.log(response.data);
                        this.in_progress = false;
                        this.result_price = response.data.data.total ;
                        this.result_price_commission = this.selected_startdate.commission * this.form.adult ;
                    }).catch((error) => {
                        this.$Progress.finish();
                        this.in_progress = false;
                        if (error.response) {
                            this.form.errors.errors = error.response.data ; // programatic erros form
                            // console.log(this.form.errors.has('baby'));
                            // console.log(this.form.errors.has('child'));
                            // console.log(error.response.data);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                        }
                        // console.log(response.toJSON());
                    });
                }
            },
            changeBook(order_id) {
                Swal.fire({
                    title: 'Chuyển đổi đơn từ giữ chỗ 24h sang đặt chỗ ?',
                    text: "Việc chuyển đổi sẽ tạo thuận tiện cho việc lên đơn hàng và sắp xếp chỗ hành khách nhanh hơn !",
                    showCancelButton: true,
                    confirmButtonColor: '#00dd4e',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Đồng ý !'
                }).then((result) => {
                    // console.log(result);
                    // Send request to the server
                    if (result.value) {
                        this.$helpers.changeBook(order_id).then(()=>{
                            Swal.fire(
                                'Thành công!',
                                'Chuyển đổi đơn đặt chỗ thành công ! Đơn sẽ không bị huỷ sau 24h',
                                'success'
                            );
                            // Fire.$emit('AfterCreate');
                            this.$router.push('/dashboard');
                        }).catch((error)=> {
                            let message = 'Something went wrong. Please try again.' ;
                            if (typeof error.response.data === 'object') {
                                message = error.response.data.message;
                            }
                            Swal.fire("Thất bại !", message, "warning");
                        });
                    }
                });
            },
            deleteOrder(order_id) {
                Swal.fire({
                    title: 'Bạn có chắc chắn muốn huỷ vé này ?',
                    text: "Vé đặt chỗ này sẽ được huỷ ngay lập tức !",
                    showCancelButton: true,
                    confirmButtonColor: '#ff0000',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Đồng ý !'
                }).then((result) => {
                    // console.log(result);
                    // Send request to the server
                    if (result.value) {
                        this.$helpers.deleteBook(order_id).then(()=>{
                            Swal.fire(
                                'Thành công!',
                                'Đã huỷ đơn thành công',
                                'success'
                            );
                            $('#showBook').modal('hide');
                            // Fire.$emit('AfterCreate');
                            this.$router.push('/dashboard');
                        }).catch((error)=> {
                            let message = 'Something went wrong. Please try again.' ;
                            if (typeof error.response.data === 'object') {
                                message = error.response.data.message;
                            }
                            Swal.fire("Thất bại !", message, "warning");
                        });
                    }
                })
            }
        },
        async mounted() {
            this.$Progress.start();
            // console.log('Booking Component mounted.')
            // console.log(this.id);
            // console.log(window.selected_startdate);
            if(this.id && (!window.selected_startdate || window.selected_startdate.id == this.id)){// tạo mới từ ngày khởi hành
                this.$helpers.getTours({id : this.id}).then((response) =>{
                    this.$Progress.finish();
                    // console.log(response.data);
                    if(response.data.data.length === 1){
                        this.selected_startdate = response.data.data[0];
                        this.selected_startdate.addings = JSON.parse(this.selected_startdate.addings);
                        this.result_price = this.selected_startdate.adult_price*1;
                        this.selected_startdate.addings.map(x=> {
                            if(x.required ==='true'){
                                this.result_price += x.price*1;
                            }
                        });
                        this.result_price_commission = this.form.adult* this.selected_startdate.commission ;
                    } else {
                        Swal.fire({
                            title: 'Có lỗi?',
                            text: "Tour không tồn tại !",
                            showCancelButton: false,
                            confirmButtonColor: '#ff0000',
                            confirmButtonText: 'Trở lại trang chủ'
                        }).then((result) => {
                            // console.log(this.id);
                            // console.log(window.selected_startdate);
                            // console.log(response.data.data);
                            this.$router.push('/dashboard');
                        })
                    }
                });
            }
            else {
                if(this.order_id){ // từ đơn hàng edit
                    this.$helpers.getOrders({id : this.order_id , is_owner: 1}).then((response) =>{
                        this.$Progress.finish();
                        if(response.data.data.length === 1){
                            this.selected_startdate = response.data.data[0].startdate;
                            this.order = response.data.data[0];
                            this.selected_startdate.addings = JSON.parse(this.selected_startdate.addings);
                            this.result_price = this.selected_startdate.adult_price*1;
                            this.selected_startdate.addings.map(x=> {
                                if(x.required ==='true'){
                                    this.result_price += x.price*1;
                                }
                            });
                            this.voucher = this.order.promotion_code ;
                            this.form.fill({
                                id : this.selected_startdate.id,
                                order_id : this.order.id,
                                fullname : this.order.agency_customer ? this.order.agency_customer.fullname : '',
                                address : this.order.agency_customer ?this.order.agency_customer.address : '',
                                phone : this.order.agency_customer ? this.order.agency_customer.phone : '',
                                email : this.order.agency_customer ? this.order.agency_customer.email : '',
                                tour_phone : this.order.agency_customer ? this.order.agency_customer.tour_phone  : '',
                                voucher : this.order.promotion_code ? this.order.promotion_code.code : '' ,
                                adult : this.order.adult ,
                                child : this.order.child ,
                                baby : this.order.baby ,
                                is_vat : this.order.is_vat ,
                                vat_info : this.order.vat_info,
                                notice : this.order.notice_agency
                            });
                            this.result_price_commission = this.order.commission*1 ?  this.order.commission : this.order.roses ;
                        } else {
                            Swal.fire({
                                title: 'Có lỗi?',
                                text: "Tour không tồn tại !",
                                showCancelButton: false,
                                confirmButtonColor: '#ff0000',
                                confirmButtonText: 'Trở lại trang chủ'
                            }).then((result) => {
                                // console.log(this.order_id);
                                // console.log(response.data.data);
                                this.$router.push('/dashboard');
                            })
                        }
                    });
                } else {
                    // console.log(this.order_id);
                    this.$router.push('/dashboard');
                }
                this.$Progress.finish();
            }
            this.$Progress.finish();
        },
        updated() {

        },
        created() {

        },
        watch : {
            $route (to, from){
                // console.log(to,from);
            }
        }
    }
</script>

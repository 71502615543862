<template>
    <div class="row ml-0 w-100 rounded border-main border-bottom border-top border-left border-right p-2 mb-2">
        <div class="col-12 col-lg-3">
            <div>
                <i class="fa fa-star color-main"></i>
                <a :href="baseTourURL + tour.tour_slug" target="_blank">{{ tour.tour_name }}</a>
            </div>
            <div>
                <i class="fa fa-play color-main"></i>
                <span>Khởi hành : {{ tour.startdate | formatDate }}</span>
            </div>
            <div>
                <i class="fa fa-clock color-main"></i>
                <span>{{ tour.tour_period }}</span>
            </div>
            <div>
                <i class="fa fa-key color-main"></i>
                <span>{{ tour.tour_code }}</span>
            </div>
            <div>
                <i class="fa fa-download color-main"></i>
                Tải về chương trình Tour
                <a :href="baseTourURL + '/home/getChapter/'+tour.tour_id+'?type=pdf'" target="_blank"><i class="fa fa-2x fa-file-pdf" ></i></a>
                <a v-if="tour.tour_chapter" :href="baseTourURL + 'upload/file/chapter/'+tour.tour_chapter" target="_blank"><i class="fa fa fa-2x fa-file-word" ></i></a>
            </div>
        </div>
        <div class="col-12 col-lg-2 border-left border-sub">
            <div class="text-success" role='button' @click="showOrders"><i class="fa fa-user-check font-weight-bold"></i>&nbsp;<span class="font-weight-bold">Đã bán : </span><span class="pl-2">{{  tour.taken_seats_count || 0 }}/{{ tour.seats_count || 0 }}</span></div>
            <div class="text-primary" role='button'><i class="fa fa-user-clock font-weight-bold"></i>&nbsp;<span class="font-weight-bold">Giữ 24h : </span><span class="pl-2">{{ tour.taken_24h_agency_seat || 0 }}/{{ tour.save_agency || 0}}</span></div>
            <div class="text-danger" role='button'><i class="fa fa-user-plus font-weight-bold"></i>&nbsp;<span class="font-weight-bold">Còn : </span><span class="pl-2">{{ tour.save_agency-tour.taken_agency_seat }}</span></div>
        </div>
        <div class="col-12 col-lg-4 border-left border-sub">
            <div class="font-weight-bold text-danger">{{ tour.notice_agency}}</div>
            <div class="row" v-if="tour.traffic_start_info && tour.traffic_start">
                <div class="col-12 col-lg-4"><i class="fa fa-calendar-days text-primary pr-2"></i><span class="text-primary">{{ tour.traffic_start }}</span></div>
                <div class="col-12 col-lg-8 text-primary">
                    <i class="fa fa-train text-primary " v-if="tour.traffic == 9"></i>
                    <i class="fa fa-plane text-primary" v-else-if="tour.traffic == 10"></i>
                    <i class="fa fa-bus text-primary" v-else></i>
                    <span class="text-primary pl-2">{{ tour.traffic_start_info }}</span>
                </div>
            </div>
            <div class="row" v-if="tour.traffic_end_info && tour.traffic_end">
                <div class="col-12 col-lg-4"><i class="fa fa-calendar-days text-success pr-2"></i><span class="text-success">{{ tour.traffic_end }}</span></div>
                <div class="col-12 col-lg-8 text-success">
                    <i class="fa fa-train text-success" v-if="tour.traffic == 9"></i>
                    <i class="fa fa-plane text-success" v-else-if="tour.traffic == 10"></i>
                    <i class="fa fa-bus text-success" v-else></i>
                    <span class="text-success pl-2">{{ tour.traffic_end_info }}</span>
                </div>
            </div>
            <div class="text-center text-blue" v-if="tour.tour_guide_info">
                <i class="fa fa-flag"></i><span class="font-weight-bold pl-2">TL : {{ tour.tour_guide_info }}</span>
            </div>
        </div>
        <div class="col-12 col-lg-3 border-left border-sub">
            <div class="row">
                <div class="col-6 col-lg-6 text-center">
                    <span class="h5 text-danger font-italic font-weight-bold">{{ tour.tour_price | toCurrency }}</span>
                    <div class="font-weight-bold ">KS: {{ tour.tour_starhotel }} sao</div>
                    <div class="font-weight-bold ">Huê Hồng</div>
                    <div class="text-blue font-weight-bold">{{ tour.commission | toCurrency }}</div>
                </div>
                <div v-if="tour.save_agency-tour.taken_agency_seat > 0" class="col-6 col-lg-6 text-center">
                    <button class="btn btn-danger" @click="book24h()"><i class="fa fa-cart-plus pr-2"></i>Giữ chỗ</button>
                </div>
                <div v-else class="col-6 col-lg-6 text-center">
                    <span class="text-danger font-weight-bold">Hết chỗ</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props :{
            tour : Object
        },
        data () {
            return {
                baseTourURL : '',
            }
        },
        methods: {
            showOrders() {
                this.$parent.$emit('showOrders' , 1 ,this.tour) ;
            },
            book24h() {
                window.selected_startdate = this.tour;
                this.$router.push({ path : '/booking/tour/' + this.tour.id});
            }
        },
        mounted () {
            this.baseTourURL = window.baseTourURL;
        },
        created() {
            this.$Progress.start();
            this.$Progress.finish();
        }
    }
</script>

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
import moment from 'moment';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Form, HasError, AlertError } from 'vform';
window.Form = Form;

import Gate from "./Gate";
if(window.user) {
    Vue.prototype.$gate = new Gate(window.user);
    window.axios.defaults.headers.common['Authorization'] =  'Bearer ' + window.user.token ;
}

axios.defaults.baseURL = 'https://haidangtravel.com/api/';
window.avatar_base_url = 'https://haidangtravel.com/image/avatar/';
window.logo_base_url = 'https://haidangtravel.com/images/logo.png';
window.baseTourURL = 'https://haidangtravel.com/';
import Swal from 'sweetalert2';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
  });

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)
Vue.use(VueAxios, axios)
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';
const router = new VueRouter({
    mode: 'history',
    routes
});
if(!window.user){
    router.push('/login')
}
// Routes End
// Vue plugin
import helpers from './function/helpers'
const plugin = {
    install () {
        Vue.helpers = helpers
        Vue.prototype.$helpers = helpers
    }
}
Vue.use(plugin)
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// Components
import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('dashboard', require('./components/Dashboard.vue'));
Vue.component('login', require('./components/auth/Login.vue'));

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);
// Filter Section

Vue.filter('getOrderBgRow',function(status){
    let bg_text = '';
    status = status*1;
    switch (status) {
        case 0 :
            bg_text = 'bg-red';
            break;
        case 1 :
            bg_text = 'bg-primary';
            break;
        case 4 :
            bg_text = '';
            break;
        default :
            bg_text = 'bg-success';
            break;
    }
    return bg_text ;
});
Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});
Vue.filter('formatDate',function(created){
    return created ? moment(created).format('DD/MM/YYYY') : '';
});
Vue.filter('formatDateTime',function(created){
    return created ? moment(created).format('DD/MM/YYYY HH:mm:ss') : '';
});
Vue.filter('formatCustomer',function(value){
    return value ? value : 'Trống';
});
Vue.filter('formatZero',function(value){
    return value*1 === 0 ? '' : value;
});
Vue.filter('toCurrency', function (value) {
    if (isNaN(parseInt(value))) {
        return value;
    }
    var formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));
Vue.filter('getTourCode' , helpers.getTourCode );
// end Filter
Vue.component('sidebar-menu',require('./components/side-bar/SideBar.vue').default)
Vue.component('vue-dropzone', vue2Dropzone);
Vue.component('datepicker', require('./components/DatePicker.vue').default);
Vue.component('filter-zone', require('./components/partial/Filter.vue').default);
Vue.component('filter-order-zone', require('./components/partial/FilterOrder.vue').default);
Vue.component('tour-list', require('./components/partial/TourList.vue').default);
Vue.component('tour-book', require('./components/partial/TourBookItem.vue').default);
Vue.component('order-list', require('./components/partial/OrderList.vue').default);
const app = new Vue({
    el: '#app',
    router
});

<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Hướng dẫn sử dụng</h3>
                <div class="card-tools">

                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-2 table-responsive">
                  <div class="row d-block p-3">
                      <button class="btn btn-primary " type="button" data-toggle="collapse" data-target="#Dashboard" aria-expanded="false" aria-controls="Dashboard">
                          1.Dashboard
                      </button>
                      <div></div>
                      <div class="collapse" id="Dashboard">
                          <div class="card card-body">
                              <div class="">Bao gồm bộ lọc các đơn hàng đã được người dùng đặt và kết quả</div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">1.Bộ lọc đơn</div>
                              <div class="col-12 text-center">
                                  <img src="/images/filter_order.jpg" class="w-100">
                              </div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">2.Các thao tác bộ lọc</div>
                              <div class="ml-5 ">1-Mã đơn : lọc đơn hàng theo mã đơn đã có</div>
                              <div class="ml-5 ">2-Tên tour : lọc đơn hàng theo tên tour</div>
                              <div class="ml-5 ">3-Ngày khởi hành : lọc đơn hàng theo ngày khởi hành</div>
                              <div class="ml-5 ">4-Trạng thái đơn : lọc đơn hàng theo trạng thái đơn hang . Có thể chọn nhiều trạng thái</div>
                              <div class="ml-5 ">5-Hướng dẫn màu sắc các trạng thái đơn hàng : lọc đơn hàng theo trạng thái đơn hàng . Có thể chọn nhiều trạng thái</div>
                              <div class="ml-5 pb-3">6-Tìm kiếm : bắt đầu tìm kiếm các đơn hàng theo các điều kiện lọc ở trên. Kết quả sẽ được hiển thị ở phần bảng kết quả</div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">3.Bảng kết quả</div>
                              <div class="col-12 text-center">
                                  <img src="/images/order_list.jpg" class="w-100">
                              </div>
                              <div class="ml-5 ">1-Các trường thông tin đơn </div>
                              <div class="ml-5 pb-3">2-Nhóm thao tác xử lý đơn (tuỳ vào các trạng thái đơn hàng)</div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">4.Các thao tác bảng kết quả</div>
                              <div class="col-12 col-md-6 text-center">
                                  <img src="/images/order_action_button.jpg" class="w-50">
                              </div>
                              <div class="ml-5 ">1-Xem thông tin đơn hàng - nếu đơn hang được quyền chỉnh sửa thì sẽ là chỉnh sửa đơn hàng </div>
                              <div class="ml-5 ">2-Chuyển trạng thái đơn từ <span class="font-weight-bold">Giữ chỗ 24h</span> sang <span class="font-weight-bold">Đặt chỗ</span> </div>
                              <div class="ml-5 ">3-Chỉnh sửa đơn hàng </div>
                              <div class="ml-5 pb-3">4-Huỷ đơn : chỉ áp dụng cho đơn hàng <span class="font-weight-bold">Giữ chỗ 24h</span> và <span class="font-weight-bold">Đặt chỗ</span></div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2" id="view_update_order">5.Xem hay chỉnh sửa đơn hàng</div>
                              <div class="col-12 text-center">
                                  <img src="/images/order_edit.jpg" class="w-100">
                              </div>
                              <div class="ml-5 ">1-Tên tour và mã đơn</div>
                              <div class="ml-5 ">2-Tình trạng đơn <span class="font-weight-bold">Giữ chỗ 24h</span> sang <span class="font-weight-bold">Đặt chỗ</span> </div>
                              <div class="ml-5 ">3-Chuyển trạng thái đơn từ <span class="font-weight-bold">Giữ chỗ 24h</span> sang <span class="font-weight-bold">Đặt chỗ</span>( Giống 4.Các thao tác bảng kết quả > 2-Chuyển trạng thái đơn) - <span class="font-italic">Chỉ xuất hiện khi đơn hàng có trạng thái </span><span class="font-weight-bold">Giữ chỗ 24h</span></div>
                              <div class="ml-5 ">4-Thông tin nhân viên quản lý và các thông tin của ngày khởi hành</div>
                              <div class="ml-5 ">5-Giá tour cơ bản và các khoản phụ thu</div>
                              <div class="ml-5 ">6-Tổng cộng giá đơn hàng</div>
                              <div class="ml-5 ">7-Tổng cộng huê hồng cho đơn hàng</div>
                              <div class="ml-5 text-primary font-weight-bold font-italic">Nếu đơn có sử dụng voucher thì giá trị và thông tin voucher sẽ hiện tại đây</div>
                              <div class="ml-5 ">8-Thông tin họ tên khách đặt tour - <span class="font-weight-bold">bắt buộc</span></div>
                              <div class="ml-5 ">9-Thông tin địa chỉ - nơi đón - <span class="font-weight-bold">bắt buộc</span></div>
                              <div class="ml-5 ">10-Số điện thoai người đại diện : có thể nhập số điện thoại khách hay đại lý - <span class="font-weight-bold">bắt buộc</span></div>
                              <div class="ml-5 ">11-Email đại diện : có thể nhập email khách hay đại lý - <span class="font-weight-bold">bắt buộc</span></div>
                              <div class="ml-5 ">12-Lấy số điện thoại đại lý điền vào thông tin số điện thoại đại diện</div>
                              <div class="ml-5 ">13-Lấy email đại lý điền vào thông tin email đại diện</div>
                              <div class="ml-5 ">14-Số điện thoại khách mua tour</div>
                              <div class="ml-5 ">15-Mã voucher giảm giá</div>
                              <div class="ml-5 ">16-Số lượng người lớn đi tour (từ 1-50) </div>
                              <div class="ml-5 ">17-Số lượng trẻ em (2 - 11 tuổi) đi tour (từ 0-50)</div>
                              <div class="ml-5 ">18-Số lượng em bé (dưới 2 tuổi) đi tour (từ 0-50)</div>
                              <div class="ml-5 ">19-Ghi chú gửi đến nhân viên quản lý</div>
                              <div class="ml-5 ">20-Đơn hàng có xuất VAT hay không </div>
                              <div class="ml-5 ">21-Thông tin xuất VAT</div>
                              <div class="ml-5 ">22-Reset lại các trường thông tin</div>
                              <div class="ml-5 ">23-Xác nhận cập nhật thông tin</div>
                              <div class="ml-5 text-primary font-weight-bold font-italic ">Sau khi xác nhận thông tin thì đơn hàng sẽ được cập nhật và chuyển về trang Dashboard</div>
                              <div class="ml-5 text-primary font-weight-bold font-italic pb-3">Nút Reset(22) và Lưu thông tin(23) chỉ hiện thị với đơn có thể được cập nhật</div>
                          </div>
                      </div>
                      <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#Tour" aria-expanded="false" aria-controls="Tour">
                          2.Tour trong nước hay nước ngoài
                      </button>
                      <div></div>
                      <div class="collapse" id="Tour">
                          <div class="card card-body">
                              <div class="">Bao gồm bộ lọc các tour đang được mở bán và kết quả</div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">1.Bộ lọc tour</div>
                              <div class="col-12 text-center">
                                  <img src="/images/filter.jpg" class="w-100">
                              </div>
                              <div class="ml-5 ">1-Lọc tour theo ngày khởi hành</div>
                              <div class="ml-5 ">2-Lọc tour theo điểm đến - có thể chọn nhiều</div>
                              <div class="ml-5 ">3-Lọc theo tên tour</div>
                              <div class="ml-5 pb-3">4-Xác nhận lọc tour theo điều kiện trên . Kết quả sẽ trả về bảng kết quả</div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">2.Bảng kết quả</div>
                              <div class="col-12 text-center">
                                  <img src="/images/tour_list.jpg" class="w-100">
                              </div>
                              <div class="ml-5 ">1-Tên tour : nhấn vào để đi đến trang tour chi tiết</div>
                              <div class="ml-5 ">2-Thông tin ngày khởi hành</div>
                              <div class="ml-5 ">3-Thông tin thời gian tour</div>
                              <div class="ml-5 ">4-Thông tin mã tour</div>
                              <div class="ml-5 ">5-Tải về chương trình tour (dạng pdf)</div>
                              <div class="ml-5 ">6-Đã bán : tổng số vé đặt / tổng số chỗ của tour</div>
                              <div class="ml-5 ">7-Giữ 24h : tổng số vé đặt 24h / tổng số vé chừa cho đại lý</div>
                              <div class="ml-5 ">8-Tổng số chỗ đại lý có thể đặt</div>
                              <div class="ml-5 ">9-Thông tin ghi chú từ nhân viên quản lý</div>
                              <div class="ml-5 ">10-Thông tin thời gian đi</div>
                              <div class="ml-5 ">11-Thông tin vé đi (máy bay- tàu lưa) - icon mô tả phương tiện</div>
                              <div class="ml-5 ">12-Thông tin thời gian về</div>
                              <div class="ml-5 ">13-Thông tin vé về (máy bay- tàu lưa) - icon mô tả phương tiện</div>
                              <div class="ml-5 ">14-Ghi chú về Hướng dẫn viên</div>
                              <div class="ml-5 ">15-Giá bán tour</div>
                              <div class="ml-5 ">16-Tiêu chuẩn</div>
                              <div class="ml-5 ">17-Số tiền huê hồng cho mỗi vé người lớn</div>
                              <div class="ml-5 ">18-Nút tạo đơn <span class="font-weight-bold">Giữ chỗ 24h</span> .<span class="font-italic">Nếu đã hết chỗ đặt thì hiển thị </span><span class="text-danger font-weight-bold">Hết chỗ</span></div>
                              <div class="ml-5 text-primary font-weight-bold font-italic pb-3">Nhấn vào <span class="text-danger font-weight-bold">Giữ chỗ 24h</span> sẽ đến trang book đơn <span class="font-weight-bold">Giữ chỗ 24h</span></div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">2.Book đơn Giữ chỗ 24h</div>
                              <div class="col-12 text-center">
                                  <img src="/images/tour_book.jpg" class="w-100">
                              </div>
                              <div class="ml-5 ">Các trường thông tin tương tự như <a href="#Dashboard">1.Dashboard</a>><a href="#view_update_order">5.Xem hay chỉnh sửa đơn hàng</a> </div>
                          </div>
                      </div>
                      <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#ChangePassword" aria-expanded="false" aria-controls="ChangePassword">
                          3.Đổi mật khẩu
                      </button>
                      <div></div>
                      <div class="collapse" id="ChangePassword">
                          <div class="card card-body">
                              <div class="">Bao gồm các thông tin cơ bản của người dùng đang đăng nhập</div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">1.Thông tin cơ bản</div>
                              <div class="col-12 col-md-6 text-center">
                                  <img src="/images/profile.jpg" class="w-100">
                              </div>
                              <div class="ml-5">1.Hình avatar </div>
                              <div class="ml-5">2.Họ tên của tài khoản đăng nhập </div>
                              <div class="ml-5">3.Username đăng nhập </div>
                              <div class="ml-5">4.Số điện thoại đăng kí</div>
                              <div class="ml-5">5.Mật khẩu hiện tại</div>
                              <div class="ml-5">6.Mật khẩu mới</div>
                              <div class="ml-5">7.Xác nhận Mật khẩu mới</div>
                              <div class="ml-5">8.Đổi mật khẩu</div>
                              <div class="ml-5 pb-3">6.Tổng số chỗ của đơn (trừ đơn huỷ)</div>
                              <div class="col-12 text-center">
                                  <img src="/images/change_password.jpg" class="w-100">
                              </div>
                              <div class="ml-5">1.Họ tên đầy đủ </div>
                              <div class="ml-5">2.Username của tài khoản đăng nhập </div>
                              <div class="ml-5">3.Quyền hạn tài khoản </div>
                              <div class="ml-5">4.Tổng số huê hồng thu được (trừ đơn huỷ)</div>
                              <div class="ml-5">5.Tổng đơn hàng phát sinh (trừ đơn huỷ)</div>
                              <div class="ml-5">6.Tổng số chỗ của đơn (trừ đơn huỷ)</div>
                              <div class="ml-4 font-weight-bold pt-2 pb-2">2.Chức năng đổi mật khẩu</div>
                              <div class="ml-5">Bước 1 :  Nhập mật khẩu hiện tại</div>
                              <div class="ml-5">Bước 2 :  Nhập mật khẩu mới</div>
                              <div class="ml-5">Bước 3 :  Nhập xác nhận mật khẩu mới</div>
                              <div class="ml-5">Bước 4 :  Chọn đổi mật khẩu</div>
                              <div class="ml-5 text-primary font-weight-bold font-italic pb-3">Bước 5 :  Nhấn vào <span class="text-danger font-weight-bold">Đổi mật khẩu </span>và xác nhận . Nếu cập nhật thành công sẽ chuyển đển trang đăng nhập lại</div>
                          </div>
                      </div>
                      <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#Logout" aria-expanded="false" aria-controls="Logout">
                          4.Logout - đăng xuất
                      </button>
                      <div></div>
                      <div class="collapse" id="Logout">
                          <div class="card card-body">
                             Nhấn vào Menu Logout để đăng xuất khỏi ứng dụng
                              <div class="col-12 col-md-3 text-center">
                                  <img src="/images/logout.jpg" class="w-100">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">

              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                parkings : {},
                form: new Form({
                    id : '',
                    name: '',
                    description: '',
                })
            }
        },
        methods: {
        },
        mounted() {
            // console.log('Help' +
            //     ' Component mounted.')
        },
        created() {
            this.$Progress.start();

            this.$Progress.finish();
        }
    }
</script>

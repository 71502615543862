<template>
    <div class="card-body table-responsive p-2">
                <template class="" v-for="tour in tour_list.data" >
            <tour-book :tour="tour" :key="tour.id"></tour-book>
        </template>
    </div>
</template>
<script>
    export default {
        props :{
            tour_list : Object
        },
        data () {
            return {

            }
        },
        methods: {

        },
        mounted () {
            // console.log('Tourlist Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.$Progress.finish();
        }
    }
</script>

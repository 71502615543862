<template>
  <div class="content">
    <div class="container-fluid">
<!--        <div class="card-tools">-->
<!--            Xuất file theo : {{  search_area_options.filter(x=>x.value == search_area)[0].text }}-->
<!--            <button type="button" @click="exportExcel()" class="btn btn-primary">-->
<!--                <i class="fas fa-file-excel"></i>-->
<!--            </button>-->
<!--        </div>-->
        <div v-if="subjects.length > 0" class="subject-title-row d-flex px-5 py-2 bg-white overflow-auto">
            <div   class="subject-title btn btn-default ml-2" v-bind:class="{ 'btn-primary' : searchForm.subject === subject.value  }" v-for="subject in subjects" @click="checkSubject(subject.value)">
                <span>{{subject.name}}</span>
            </div>
            <div class="subject-title btn btn-danger ml-2" v-if="searchForm.subject" @click="checkSubject(false)">
                <span class="text-white">Xoá chọn</span>
            </div>
            <input name="search" v-model="searchForm.subject" type="hidden" />
        </div>
        <div class="row pt-2">
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>Ngày khởi hành</label>
                    <datepicker @changeDay="changeDayDB" ></datepicker>
                    <!--                        <input v-model="searchForm.startdate" type="text" name="startdate"-->
                    <!--                               class="form-control" :class="{ 'is-invalid': searchForm.errors.has('startdate') }">-->
                    <has-error :form="searchForm" field="startdate"></has-error>
                </div>
            </div>
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>Điểm đến</label>
    <!--                    <select name="search_type" v-model="searchForm.destination" id="search_type" class="form-control select2">-->
    <!--                        <option value="">Tất cả</option>-->
    <!--                        <option v-for="item in destinations" :value="item.slug">-->
    <!--                            {{ item.title }}-->
    <!--                        </option>-->
    <!--                    </select>-->
                    <multiselect
                        :custom-label="onlyName"
                        v-model="searchForm.destination"
                        placeholder="Chọn"
                        :options="destinations"
                        track-by="name"
                        deselectGroupLabel="Nhấn enter để bỏ chọn"
                        deselectLabel="Nhấn enter để bỏ chọn"
                        selectGroupLabel="Nhấn enter để chọn"
                        selectLabel="Nhấn enter để chọn"
                        :multiple="true">
                        <template slot="selection"
                                  slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single"
                                  v-if="values.length &amp;&amp; !isOpen">
                                {{ values.length }} điểm đến được chọn
                            </span>
                        </template>
                    </multiselect>
<!--                    <Select2-->
<!--                        id="#search_destinations"-->
<!--                        name="search_destination"-->
<!--                        v-model="searchForm.destination"-->
<!--                        :options="destinations"-->
<!--                        @change="selectTourTypeChange($event)" @select="selectTourTypeSelect($event)"-->
<!--                    />-->
<!--                    <vue-select class="vue-select3" name="search_type"-->
<!--                                :options="destinations" :model.sync="searchForm.destination"-->
<!--                                :searchable="true" language="vi-VN">-->
<!--                    </vue-select>-->
                    <has-error :form="searchForm" field="                                                                                                                                                                                         search_destination"></has-error>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <label>Tìm kiếm theo tên tour</label>
                <div class="input-group">
                    <input name="search" v-model="searchForm.search" class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                    <div class="input-group-append">
                        <button class="btn btn-success input-group-text " type="button" @click="getFilterResults">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
                <has-error :form="searchForm" field="search"></has-error>
            </div>
        </div>
    </div>
      <div class="tour-header d-flex">
          <div>Tổng cộng : <span class="total font-weight-bold text-primary">{{ tour_list.total || 0 }} tour</span></div>
          <div class="pl-2">Hiển thị :
              <select v-model="searchForm.current_show" @change="getFilterResults">
                  <option >10</option>
                  <option >20</option>
                  <option >30</option>
                  <option >40</option>
                  <option >50</option>
                  <option >100</option>
              </select>
          </div>
      </div>
  </div>
</template>
<script>
    // import DatePicker from 'vue2-datepicker';
    // import { Datetime } from 'vue-datetime';
    // import 'vue-datetime/dist/vue-datetime.css'
    // import 'vue2-datepicker/index.css';
    // import 'vue2-datepicker/locale/vi';
    // import moment from "moment";
    // import { Settings } from 'luxon';
    // Settings.defaultLocale = 'vi';
    export default {
        props : {
            tour_list : Array,
            destinations : Array,
            subjects : Array,
        },
        data () {
            return {
                searchForm :  new Form({
                    startdate : false,
                    destination : [],
                    search :'',
                    from_child : true ,
                    subject : '',
                    current_show : 20
                }),
            }
        },
        methods: {
            getResults(page = 1) {
                  this.$Progress.start();
                  // axios.get('/api/tag?page=' + page).then(({ data }) => (this.tags = data.data));
                  this.$Progress.finish();
            },
            changeDayDB(newDay) {
                this.searchForm.startdate =  newDay ;
            },
            selectTourTypeChange(val){
                // console.log(val);
            },
            selectTourTypeSelect({id, text}){
                // console.log({id, text})
            },
            onlyName ({ name, value }) {
                return name ;
            },
            getFilterResults(){
                this.$emit('getResults', null, { search_date : this.searchForm.startdate , search_destinations : this.searchForm.destination , search : this.searchForm.search , from_child :   true , search_subject : this.searchForm.subject , current_show : this.searchForm.current_show } );
            },
            checkSubject(subject) {
                this.searchForm.subject =  subject ;
                this.getFilterResults();
            }
        },
        async mounted () {
            // console.log('Filter Component mounted.')
        },
        created() {
            this.$Progress.start();
            console.log(this.subjects);
            this.$Progress.finish();
        },
        watch : {
            async $route (to, from){
                if(to.path === '/tour-trong-nuoc' || to.path === '/tour-nuoc-ngoai'){
                    this.searchForm.subject = '';
                }
            }
        }
    }
</script>

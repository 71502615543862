<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <div class="text-center">
                                <img class="profile-user-img img-fluid img-circle" :src="this.avatar_url" alt="Hình đại diện">
                            </div>
                            <h3 class="profile-username text-center">{{ user.fullname }}</h3>
                            <p class="text-muted text-center">{{ user.role }}</p>
                            <ul class="list-group list-group-unbordered mb-3">
                                <li class="list-group-item">
                                    <b>Tổng huê hồng thu đươc</b><a class="float-right">{{ total_commission | toCurrency }}</a>
                                </li>
                                <li class="list-group-item">
                                    <b>Tổng đơn hàng</b> <a class="float-right">{{ total_order}} đơn</a>
                                </li>
                                <li class="list-group-item">
                                    <b>Tổng số khách</b> <a class="float-right">{{ total_seat}} chỗ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="card">
                        <div class="card-header p-2">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#settings" data-toggle="tab">Thông tài khoản</a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content">
                                <div class="tab-pane active" id="settings">
                                    <form class="form-horizontal">
                                        <div class="form-group row">
                                            <label for="fullname" class="col-sm-2 col-form-label">Họ tên</label>
                                            <div class="col-sm-10">
                                                <input disabled v-model="form.fullname" type="fullname" class="form-control" id="fullname" placeholder="Họ tên">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="username" class="col-sm-2 col-form-label">Username</label>
                                            <div class="col-sm-10">
                                                <input disabled v-model="form.username" type="username" class="form-control" id="username" placeholder="Username">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="email" class="col-sm-2 col-form-label">Email</label>
                                            <div class="col-sm-10">
                                                <input  disabled v-model="form.email" type="email" class="form-control" id="email" placeholder="Email">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="phone" class="col-sm-2 col-form-label">Số điện thoại</label>
                                            <div class="col-sm-10">
                                                <input disabled v-model="form.phone" type="phone" class="form-control" id="phone" placeholder="phone">
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="form-group row">
                                            <label for="old_password" class="col-sm-2 col-form-label">Mật khẩu hiện tại</label>
                                            <div class="col-sm-10">
                                                <input v-model="form.old_password" type="password" class="form-control" id="old_password" placeholder="Nhập mật khẩu hiện tại">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="new_password" class="col-sm-2 col-form-label">Mật khẩu mới</label>
                                            <div class="col-sm-10">
                                                <input @change="checkConfirm()" v-model="form.new_password" type="password" class="form-control" id="new_password" placeholder="Nhập mật khẩu mới">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="confirm_password" class="col-sm-2 col-form-label">Xác nhận mật khẩu mới</label>
                                            <div class="col-sm-10">
                                                <input @change="checkConfirm()" v-model="form.confirm_password" type="password" class="form-control" id="confirm_password" placeholder="Xác nhận mật khẩu mới">
                                            </div>
                                            <span v-if="!check_confirm" class="text-danger">* Mật khẩu xác nhận không đúng !</span>
                                        </div>
                                        <div class="form-group row">
                                            <div class="offset-sm-2 col-sm-10">
                                                <button type="button" @click="updateUser()" class="btn btn-danger">Đổi mật khẩu</button>
                                                <span class="text-danger">* Buộc đăng nhập lại !</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data() {
            return {
                user: {}, form: new Form({
                    avatar: "", email: "", fullname: "",
                    id: '', phone: "", role: "", username: "",
                    old_password : '' , new_password : '' , confirm_password : ''
                }),
                avatar_url : '' ,
                check_confirm : true ,
                total_commission : 0 ,
                total_order : 0 ,
                total_seat : 0 ,
            }
        }, methods: {
            updateUser() {
                this.$Progress.start();
                // console.log('Editing data');
                if(this.check_confirm){
                    this.$helpers.changePassword({ UserName : this.user.username , Password : this.form.old_password , NewPassword  : this.form.new_password})
                        .then((response) => {
                            // success
                            Swal.fire("Thành công !", response.data.data[0].message ,  "success"  ).then((result)=>{
                                    window.user = null ;
                                    window.location = '/logout';
                            });
                            this.$Progress.finish();
                            //  Fire.$emit('AfterCreate');
                        })
                        .catch((error) => {
                            let message = 'Something went wrong. Please try again.' ;
                            if (typeof error.response.data === 'object') {
                                message = error.response.data.data[0].message;
                            }
                            Swal.fire("Thất bại !", message, "warning" , function () {

                            });
                            this.$Progress.fail();
                        });
                }
            },
            checkConfirm() {
                if(this.form.confirm_password){
                   if(this.form.confirm_password !== this.form.new_password){
                       this.check_confirm = false ;
                   }
                }
            }
        }, mounted() {
            // console.log('ChangePassword Component mounted.')
            this.user = window.user;
            this.avatar_url = window.avatar_base_url+user.avatar;
            this.form.fill(this.user);
            this.$helpers.getProfile().then((response)=>{
                this.total_commission = response.data.total_commission;
                this.total_order = response.data.total_order;
                this.total_seat = response.data.total_seat;
            });
        }, created() {
            this.$Progress.start();
            this.$Progress.finish();
        }
    }
</script>
